import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonTitle,
    IonToolbar,
    useIonLoading,
} from '@ionic/react';
import React from 'react';
import {Map, MapCameraChangedEvent, Marker, useMap} from '@vis.gl/react-google-maps';
import {useLoginState} from '../../data/Login';
import {ShortSpot, spotRepository} from '../../data/repository/Spot';
import {Alert} from '@mui/material';
import {useOnlineState} from '../../data/Helpers';
import {useI18n} from '../../i18n/i18n';

const INITIAL_LOCATION = {
    lat: 46.8182,
    lng: 8.2275,
};

interface LocationCreateModalProps {
    onDismiss: () => void,
    onCreate: (location: ShortSpot) => void,
}

export const LocationCreateModal: React.FunctionComponent<LocationCreateModalProps> = (props) => {
    const [isOnline] = useOnlineState();
    const map = useMap();
    const [longitude, setLongitude] = React.useState<number>(INITIAL_LOCATION.lng);
    const [latitude, setLatitude] = React.useState<number>(INITIAL_LOCATION.lat);
    const [name, setName] = React.useState<string>('');
    const [error, setError] = React.useState<string | null>(null);
    const {user} = useLoginState();
    const {label} = useI18n();
    const [canCreate, setCanCreate] = React.useState<boolean>(false);
    const [showLoadingModal, dismissLoadingModal] = useIonLoading();

    const create = async () => {
        setError(null);
        if (name === '') {
            return;
        }

        showLoadingModal(label('location_create.loading'));

        spotRepository.create(user!.token, name, latitude, longitude).then(spot => {
            props.onCreate(spot);
            dismissLoadingModal();
        }).catch(e => {
            setError(`${e}`);
            dismissLoadingModal();
        });
    };


    React.useEffect(() => {
        if (!map) {
            return;
        }

        // TODO: CHECK IF WORKS ON NATIVE
        navigator.geolocation.getCurrentPosition(
            location => {
                setLatitude(location.coords.latitude);
                setLongitude(location.coords.longitude);
                map.setCenter({lat: location.coords.latitude, lng: location.coords.longitude});
                map.setZoom(12);
            },
            // eslint-disable-next-line no-console
            error => console.error(error),
            {enableHighAccuracy: true},
        );
    }, [map]);

    React.useEffect(() => {
        setCanCreate(isOnline && name !== '' && longitude !== INITIAL_LOCATION.lng && latitude !== INITIAL_LOCATION.lat);
    }, [isOnline, longitude, latitude, name]);

    const onCenterChanged = (e: MapCameraChangedEvent) => {
        setLatitude(e.detail.center.lat);
        setLongitude(e.detail.center.lng);
    };

    return <React.Fragment>
        <IonHeader>
            <IonToolbar>
                <IonTitle>
                    {label('location_create.title')}
                </IonTitle>
                <IonButtons slot="end">
                    <IonButton color="danger" onClick={() => props.onDismiss()}>
                        {label('location_create.action.cancel')}
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>

                {error === null ? null : <Alert severity="error">{label('location_create.error.generic')}</Alert>}

                <IonItem>
                    <IonInput
                        type="text"
                        label={label('location_create.label') + '*'}
                        value={name}
                        onIonChange={e => setName(e.detail.value!)}
                        placeholder={label('location_create.label.placeholder')}
                    />
                </IonItem>

                <Map
                    disableDefaultUI={true}
                    onCenterChanged={onCenterChanged}
                    defaultZoom={8}
                    defaultCenter={INITIAL_LOCATION}
                >
                    <Marker position={{lat: latitude, lng: longitude}}/>
                </Map>

                {!isOnline && <Alert severity="warning">
                    <h2>{label('location_create.error.no_internet.title')}</h2>
                    <p>{label('location_create.error.no_internet.message')}</p>
                </Alert>}

                <IonButton disabled={!canCreate} onClick={create}>
                    {label('location_create.action.create')}
                </IonButton>
            </div>
        </IonContent>
    </React.Fragment>;
};