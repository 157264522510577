import React from 'react';
import './Home.css';
import {PageWrapper} from '../components/PageWrapper';
import {IonButton, IonIcon, IonImg, IonList, IonSelect, IonSelectOption, useIonLoading} from '@ionic/react';
import logo from '../assets/logo.svg';
import {useLoginState} from '../data/Login';
import {addOutline, logInOutline, logOutOutline, removeOutline} from 'ionicons/icons';
import {environment} from '../environment';
import {PwdInput} from '../components/PwdInput';
import {conditionsReportEditingStorage} from '../data/storage';
import {ID_NEW} from '../data/ConditionsReportProEditing';
import {Alert} from '@mui/material';
import {useOnlineState} from '../data/Helpers';
import {useNews} from '../data/News';
import {MultipleNews} from '../components/News';
import {useI18n} from '../i18n/i18n';
import {temporaryFiles} from '../data/files';

const Home: React.FC = () => {
    const news = useNews();
    const {user, logout, isFixed} = useLoginState();
    const {label, locales, locale, setLocale} = useI18n();

    const [tapsOnBuild, setTapsOnBuild] = React.useState<number>(0);

    const languageLabel = Object.keys(locales).map(lang => label('language', {}, lang)).join(' / ');

    if (user === null && isFixed) {
        return <PageWrapper title="PowderGuide">
            <Alert severity="info">
                <h3>{label('home.fixed_login.title')}</h3>
                <p>{label('home.fixed_login.message')}</p>
            </Alert>
        </PageWrapper>;
    }

    return <PageWrapper title="PowderGuide">
        <IonImg src={logo}/>
        <MultipleNews news={news}/>
        <div className="ion-padding-horizontal">
            <h3>{label('home.title', {user: user?.username ?? ''})}</h3>

            <p
                className="ion-padding-bottom"
                dangerouslySetInnerHTML={{__html: label('home.text', {mail: 'app@powderguide.com'})}}
            />
            <p>
                {label('home.signoff')}
            </p>
        </div>

        {environment.enableMultilang && <div className="ion-padding-horizontal">
            <IonSelect label={languageLabel} value={locale} onIonChange={e => setLocale(e.detail.value)}>
                {Object.entries(locales).map(([locale, name]) => <IonSelectOption key={locale} value={locale}>{name}</IonSelectOption>)}
            </IonSelect>
        </div>}

        {user === null ? <LoginForm/> : <CurrentUserMenu isFixed={isFixed} logout={logout}/>}

        <div className="ion-padding" style={{marginTop: 75, color: '#ccc', fontSize: '.8em'}}
             onClick={() => setTapsOnBuild(tapsOnBuild + 1)}>
            Version {environment.version}
        </div>

        {tapsOnBuild >= 5 && <div className="ion-padding-top">
            <IonButton routerLink="/debug">Debug</IonButton>
        </div>}
    </PageWrapper>;
};

interface CurrentUserMenuProps {
    isFixed: boolean,
    logout: () => any
}

const CurrentUserMenu: React.FunctionComponent<CurrentUserMenuProps> = (props) => {
    const [hasActiveProCr, setHasActiveProCr] = React.useState<boolean>(false);
    const [hasActiveMiniCr, setHasActiveMiniCr] = React.useState<boolean>(false);
    const {label} = useI18n();

    React.useEffect(() => {
        conditionsReportEditingStorage.hasProStepData(ID_NEW).then(setHasActiveProCr);
        conditionsReportEditingStorage.hasMiniFormData(ID_NEW).then(setHasActiveMiniCr);
    }, []);

    const clearCurrentProCreation = async () => {
        await conditionsReportEditingStorage.removeProStepData(ID_NEW);
        await temporaryFiles.clearStorage();
        setHasActiveProCr(false);
    };

    const clearCurrentMiniCreation = async () => {
        await conditionsReportEditingStorage.removeMiniFormData(ID_NEW);
        await temporaryFiles.clearStorage();
        setHasActiveMiniCr(false);
    }

    return <React.Fragment>
        <IonButton routerLink="/edit/pro/new" expand="full">
            <IonIcon icon={addOutline} style={{marginRight: '.5em'}}/>
            {label(hasActiveProCr ? 'home.cr.edit' : 'home.cr.create')}
        </IonButton>
        {hasActiveProCr && <IonButton onClick={clearCurrentProCreation} expand="full">
            <IonIcon icon={removeOutline} style={{marginRight: '.5em'}}/>
            {label('home.cr.discard')}
        </IonButton>}
        <hr />
        {environment.enableMiniCrEditing && <React.Fragment>
            <IonButton routerLink="/edit/mini/new" expand="full">
                <IonIcon icon={addOutline} style={{marginRight: '.5em'}}/>
                __MINI CR__
            </IonButton>
            {hasActiveMiniCr && <IonButton onClick={clearCurrentMiniCreation} expand="full">
                <IonIcon icon={removeOutline} style={{marginRight: '.5em'}}/>
                __CLEAR MINI CR__
            </IonButton>}
        </React.Fragment>}
        <br/>
        <br/>
        <br/>
        {!props.isFixed && <IonButton color="danger" expand="full" onClick={props.logout}>
            <IonIcon icon={logOutOutline} style={{marginRight: '.5em'}}/>
            {label('home.action.logout')}
        </IonButton>}
    </React.Fragment>;
};


const LoginForm: React.FunctionComponent = () => {
    const [present, dismiss] = useIonLoading();
    const {login} = useLoginState();
    const [isOnline] = useOnlineState();
    const {label} = useI18n();

    const [username, setUsername] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [error, setError] = React.useState<string | null>(null);

    const performLogin = async () => {
        present();
        try {
            const userData = await login(username, password);
            if (userData === null) {
                setError(label('login.error.invalid_credentials'));
            }
        } catch (e) {
            setError(`${e}`);
        }
        dismiss();
    };

    return <IonList>
        {error && <div className="ion-padding-bottom">
            <Alert severity="error">{error}</Alert>
        </div>}
        <div className="ion-padding-bottom">
            <PwdInput value={username} onChange={setUsername} type="text" label={label('login.username')}/>
        </div>
        <div className="ion-padding-bottom">
            <PwdInput value={password} onChange={setPassword} type="password" label={label('login.password')}/>
        </div>
        {!isOnline && <Alert severity="warning">
            <h2>{label('login.no_internet.title')}</h2>
            <p>{label('login.no_internet.message')}</p>
        </Alert>}
        <IonButton disabled={!isOnline} expand="full" onClick={performLogin}>
            <IonIcon icon={logInOutline} style={{marginRight: '0.5rem'}}/>
            {label('login.action.login')}
        </IonButton>
        <IonList class="ion-padding-top">
            <IonButton color="secondary" href={`${environment.powderguideWebsiteUrl}/password-reset`}>
                {label('login.action.password_forgotten')}
            </IonButton>
            <IonButton color="secondary" href={`${environment.powderguideWebsiteUrl}/registration`}>
                {label('login.action.register')}
            </IonButton>
        </IonList>
    </IonList>;
};

export default Home;
