import React from 'react';
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    IonIcon,
    IonLabel,
    IonList,
    IonRange,
    IonSelect,
    IonSelectOption,
    IonTextarea,
} from '@ionic/react';
import {IonItemWithValidation} from '../../../components/ValidationWarning';
import {AvalancheType, Exposition} from '../../../data/ConditionsReport';
import {ExpositionSelectorMultiple, ExpositionSelectorSingle} from '../../../components/ExpositionSelector';
import {warningOutline} from 'ionicons/icons';
import {StepScope, StepValidationResult, ValidationScope} from '../../../data/ConditionsReportProEditing';
import {useI18n} from '../../../i18n/i18n';

export interface Step3Data {
    avalancheWarningLevel: number,
    riskDescription: string,
    riskWindIntensity: number | null,
    riskWindDirection: Exposition[],
    riskNoWarningSigns: boolean,
    riskAbort: boolean,
    riskCracking: boolean,
    riskFreshAvalanches: boolean,
    riskFreshAvalancheExposition: Exposition | null,
    riskFreshAvalancheAltitude: number | null,
    riskFreshAvalancheSize: number | null,
    riskFreshAvalancheType: AvalancheType | null,
    riskFreshAvalancheDescription: string,
    riskRockfall: boolean,
    riskCrevices: boolean,
    riskDiurnalWarming: boolean,
    riskMuchFreshSnow: boolean,
    riskMuchWater: boolean,
    riskHissingNoise: boolean,
    riskOldSnowProblem: boolean,
}

export function Step3EmptyData(): Step3Data {
    return {
        avalancheWarningLevel: 0,
        riskDescription: '',
        riskWindIntensity: null,
        riskWindDirection: [],
        riskNoWarningSigns: false,
        riskAbort: false,
        riskCracking: false,
        riskFreshAvalanches: false,
        riskFreshAvalancheExposition: null,
        riskFreshAvalancheAltitude: null,
        riskFreshAvalancheSize: null,
        riskFreshAvalancheType: null,
        riskFreshAvalancheDescription: '',
        riskRockfall: false,
        riskCrevices: false,
        riskDiurnalWarming: false,
        riskMuchFreshSnow: false,
        riskMuchWater: false,
        riskHissingNoise: false,
        riskOldSnowProblem: false,
    };
}

export function validateStep3({data, label}: ValidationScope<Step3Data>): StepValidationResult<Step3Data> {
    const validation: StepValidationResult<Step3Data> = {};

    if (data.riskWindIntensity === null) {
        validation.riskWindIntensity = [label('cr.risk.wind_intensity.validation.exists')];
    }
    if (data.riskWindIntensity !== 0 && data.riskWindDirection.length === 0) {
        validation.riskWindDirection = [label('cr.risk.wind_Direction.validation.exists')];
    }

    if (data.riskFreshAvalanches) {
        if (data.riskFreshAvalancheExposition === null) {
            validation.riskFreshAvalancheExposition = [label('cr.risk.fresh_avalanches.exposition.validation.exists')];
        }
        if (data.riskFreshAvalancheAltitude === null) {
            validation.riskFreshAvalancheAltitude = [label('cr.risk.fresh_avalanches.altitude.validation.exists')];
        }
        if (data.riskFreshAvalancheType === null) {
            validation.riskFreshAvalancheType = [label('cr.risk.fresh_avalanches.type.validation.exists')];
        }
        if (data.riskFreshAvalancheSize === null) {
            validation.riskFreshAvalancheSize = [label('cr.risk.fresh_avalanches.size.validation.exists')];
        }
    }

    return validation;
}

export const Step3Form: React.FunctionComponent<StepScope<Step3Data>> = (props) => {
    const {label} = useI18n();
    const {data, updateData, validation} = props;

    return <IonList>
        <IonItemWithValidation errors={validation.riskDescription}>
            <IonTextarea
                placeholder={label('cr.risk.description')}
                value={data.riskDescription}
                onIonChange={e => updateData({riskDescription: e.detail.value!})}
                autocapitalize="sentences"
                label="Text"
            />
        </IonItemWithValidation>

        <IonItemWithValidation errors={validation.avalancheWarningLevel}>
            <IonSelect
                value={data.avalancheWarningLevel}
                onIonChange={e => updateData({avalancheWarningLevel: e.detail.value})}
                label={label('cr.avalanche_warning_level') + '*'}
                interface="action-sheet"
            >
                <IonSelectOption value={0}>{label('cr.avalanche_warning_level.0')}</IonSelectOption>
                <IonSelectOption value={1}>{label('cr.avalanche_warning_level.1')}</IonSelectOption>
                <IonSelectOption value={2}>{label('cr.avalanche_warning_level.2')}</IonSelectOption>
                <IonSelectOption value={3}>{label('cr.avalanche_warning_level.3')}</IonSelectOption>
                <IonSelectOption value={4}>{label('cr.avalanche_warning_level.4')}</IonSelectOption>
                <IonSelectOption value={5}>{label('cr.avalanche_warning_level.5')}</IonSelectOption>
            </IonSelect>
        </IonItemWithValidation>

        <IonItemWithValidation errors={validation.riskWindIntensity}>
            <IonSelect
                value={data.riskWindIntensity}
                onIonChange={e => updateData({riskWindIntensity: e.detail.value})}
                label={label('cr.risk.wind_intensity') + '*'}
                interface="action-sheet"
            >
                <IonSelectOption value={0}>{label('cr.risk.wind_intensity.0')}</IonSelectOption>
                <IonSelectOption value={1}>{label('cr.risk.wind_intensity.1')}</IonSelectOption>
                <IonSelectOption value={2}>{label('cr.risk.wind_intensity.2')}</IonSelectOption>
                <IonSelectOption value={3}>{label('cr.risk.wind_intensity.3')}</IonSelectOption>
                <IonSelectOption value={4}>{label('cr.risk.wind_intensity.4')}</IonSelectOption>
            </IonSelect>
        </IonItemWithValidation>

        <IonItemWithValidation errors={validation.riskWindDirection}>
            <IonCard disabled={data.riskWindIntensity === 0}>
                <IonCardHeader>
                    <IonCardTitle>
                        {label('cr.risk.wind_direction')}*
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <ExpositionSelectorMultiple
                        expositions={data.riskWindDirection}
                        onChange={exposition => updateData({riskWindDirection: exposition})}
                    />
                </IonCardContent>
            </IonCard>
        </IonItemWithValidation>

        <IonItemWithValidation errors={validation.riskNoWarningSigns}>
            <IonCheckbox checked={data.riskNoWarningSigns}
                         onIonChange={e => updateData({riskNoWarningSigns: e.detail.checked})}>
                {label('cr.risk.no_warning_signs')}
            </IonCheckbox>
        </IonItemWithValidation>

        {data.riskNoWarningSigns ? null : <React.Fragment>
            <IonItemWithValidation errors={validation.riskAbort}>
                <IonCheckbox checked={data.riskAbort} onIonChange={e => updateData({riskAbort: e.detail.checked})}>
                    <IonIcon icon={warningOutline} color="danger"/>
                    {label('cr.risk.abort')}
                </IonCheckbox>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.riskOldSnowProblem}>
                <IonCheckbox checked={data.riskOldSnowProblem}
                             onIonChange={e => updateData({riskOldSnowProblem: e.detail.checked})}>
                    {label('cr.risk.old_snow_problem')}
                </IonCheckbox>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.riskCracking}>
                <IonCheckbox checked={data.riskCracking}
                             onIonChange={e => updateData({riskCracking: e.detail.checked})}>
                    {label('cr.risk.cracking')}
                </IonCheckbox>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.riskFreshAvalanches}>
                <IonCheckbox checked={data.riskFreshAvalanches}
                             onIonChange={e => updateData({riskFreshAvalanches: e.detail.checked})}>
                    {label('cr.risk.fresh_avalanches')}
                </IonCheckbox>
            </IonItemWithValidation>

            {data.riskFreshAvalanches ? <div className="ion-padding-start">
                <AvalancheEditor data={data} validation={validation} onChange={updateData}/>
            </div> : null}

            <IonItemWithValidation errors={validation.riskRockfall}>
                <IonCheckbox checked={data.riskRockfall}
                             onIonChange={e => updateData({riskRockfall: e.detail.checked})}>
                    {label('cr.risk.rockfall')}
                </IonCheckbox>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.riskCrevices}>
                <IonCheckbox checked={data.riskCrevices}
                             onIonChange={e => updateData({riskCrevices: e.detail.checked})}>
                    {label('cr.risk.crevices')}
                </IonCheckbox>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.riskDiurnalWarming}>
                <IonCheckbox checked={data.riskDiurnalWarming}
                             onIonChange={e => updateData({riskDiurnalWarming: e.detail.checked})}>
                    {label('cr.risk.diurnal_warming')}
                </IonCheckbox>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.riskMuchFreshSnow}>
                <IonCheckbox checked={data.riskMuchFreshSnow}
                             onIonChange={e => updateData({riskMuchFreshSnow: e.detail.checked})}>
                    {label('cr.risk.much_fresh_snow')}
                </IonCheckbox>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.riskMuchWater}>
                <IonCheckbox checked={data.riskMuchWater}
                             onIonChange={e => updateData({riskMuchWater: e.detail.checked})}>
                    {label('cr.risk.much_water')}
                </IonCheckbox>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.riskHissingNoise}>
                <IonCheckbox checked={data.riskHissingNoise}
                             onIonChange={e => updateData({riskHissingNoise: e.detail.checked})}>
                    {label('cr.risk.hissing_noise')}
                </IonCheckbox>
            </IonItemWithValidation>
        </React.Fragment>}
    </IonList>;
};

interface AvalancheEditorProps {
    data: Step3Data,
    validation: StepValidationResult<Step3Data>,
    onChange: (data: Partial<Step3Data>) => void,
}

const AvalancheEditor: React.FunctionComponent<AvalancheEditorProps> = (props) => {
    const {label} = useI18n();

    return <React.Fragment>
        <IonItemWithValidation errors={props.validation.riskFreshAvalancheExposition}>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>{label('cr.risk.fresh_avalanches.exposition')} *</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <ExpositionSelectorSingle
                        exposition={props.data.riskFreshAvalancheExposition}
                        onChange={exposition => props.onChange({riskFreshAvalancheExposition: exposition})}
                    />
                </IonCardContent>
            </IonCard>
        </IonItemWithValidation>

        <IonItemWithValidation errors={props.validation.riskFreshAvalancheDescription}>
            <IonTextarea
                placeholder={label('cr.risk.fresh_avalanches.description')}
                value={props.data.riskFreshAvalancheDescription}
                onIonChange={e => props.onChange({riskFreshAvalancheDescription: e.detail.value!})}
                autocapitalize="sentences"
                label="Beschreibung"
            />
        </IonItemWithValidation>

        <IonItemWithValidation errors={props.validation.riskFreshAvalancheAltitude}>
            <IonRange
                value={Number(props.data.riskFreshAvalancheAltitude)}
                onIonChange={(e: any) => props.onChange({riskFreshAvalancheAltitude: e.detail.value!})}
                min={0}
                max={4800}
                step={100}
                pin={true}
                labelPlacement="start"
            >
                <IonLabel slot="label">
                    {label('cr.risk.fresh_avalanches.altitude', {altitude: `${props.data.riskFreshAvalancheAltitude}`})}
                    *
                </IonLabel>
            </IonRange>
        </IonItemWithValidation>

        <IonItemWithValidation errors={props.validation.riskFreshAvalancheSize}>
            <IonSelect value={props.data.riskFreshAvalancheSize}
                       onIonChange={e => props.onChange({riskFreshAvalancheSize: e.detail.value})}
                       label={`${label('cr.risk.fresh_avalanches.size')}*`}
                       interface="action-sheet"
            >
                <IonSelectOption value={0}>{label('cr.risk.fresh_avalanches.size.0')}</IonSelectOption>
                <IonSelectOption value={1}>{label('cr.risk.fresh_avalanches.size.1')}</IonSelectOption>
                <IonSelectOption value={2}>{label('cr.risk.fresh_avalanches.size.2')}</IonSelectOption>
                <IonSelectOption value={3}>{label('cr.risk.fresh_avalanches.size.3')}</IonSelectOption>
                <IonSelectOption value={4}>{label('cr.risk.fresh_avalanches.size.4')}</IonSelectOption>
                <IonSelectOption value={5}>{label('cr.risk.fresh_avalanches.size.5')}</IonSelectOption>
            </IonSelect>
        </IonItemWithValidation>

        <IonItemWithValidation errors={props.validation.riskFreshAvalancheType}>
            <IonSelect value={props.data.riskFreshAvalancheType}
                       onIonChange={e => props.onChange({riskFreshAvalancheType: e.detail.value})}
                       label={`${label('cr.risk.fresh_avalanches.type')}*`}
                       interface="action-sheet"
            >
                <IonSelectOption value={AvalancheType.DryLoose}>
                    {label('cr.risk.fresh_avalanches.type.dry_loose')}
                </IonSelectOption>
                <IonSelectOption value={AvalancheType.WetLoose}>
                    {label('cr.risk.fresh_avalanches.type.wet_loose')}
                </IonSelectOption>
                <IonSelectOption value={AvalancheType.DrySlab}>
                    {label('cr.risk.fresh_avalanches.type.dry_slab')}
                </IonSelectOption>
                <IonSelectOption value={AvalancheType.WetSlab}>
                    {label('cr.risk.fresh_avalanches.type.wet_slab')}
                </IonSelectOption>
                <IonSelectOption value={AvalancheType.Gliding}>
                    {label('cr.risk.fresh_avalanches.type.gliding')}
                </IonSelectOption>
                <IonSelectOption value={AvalancheType.Wet}>
                    {label('cr.risk.fresh_avalanches.type.wet')}
                </IonSelectOption>
                <IonSelectOption value={AvalancheType.CorniceBreak}>
                    {label('cr.risk.fresh_avalanches.type.cornice_break')}
                </IonSelectOption>
                <IonSelectOption value={AvalancheType.Unknown}>
                    {label('cr.risk.fresh_avalanches.type.unknown')}
                </IonSelectOption>
            </IonSelect>
        </IonItemWithValidation>
    </React.Fragment>;
};